/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

button,
label.orange-text,
td.mat-cell,
a {
    cursor: pointer;
}
